import React from "react";
import Header from "./Header";
import InfoCustomer from "./InfoCustomer";

function VerificationPage() {
  return (
    <div className="verification_page">
      <Header />
      <InfoCustomer />
    </div>
  );
}

export default VerificationPage;
