import { create } from 'zustand';

export const useFavoriteStore = (() => {
  const cartData = localStorage.getItem('favorite') ?? '[]';

  return create((set) => ({
    favorite: JSON.parse(cartData),
    addToFavorite: (product) =>
      set((state) => {
        let favorite = [...state.favorite, product];
        localStorage.setItem('favorite', JSON.stringify(favorite));
        return { favorite };
      }),
    removeFromFavorite: (product) =>
      set((state) => {
        const favorite = state.favorite.filter((p) => p.id !== product.id);
        localStorage.setItem('favorite', JSON.stringify(favorite));
        return { favorite };
      }),
    toggleFavorite: (product) =>
      set((state) => {
        let favorite = [...state.favorite];
        const index = favorite.findIndex((p) => p.id === product.id);
        if (index !== -1) {
          favorite = favorite.filter((p) => p.id !== product.id);
        } else {
          favorite = [...favorite, product];
        }
        localStorage.setItem('favorite', JSON.stringify(favorite));
        return { favorite };
      }),
    clearFavorite: () =>
      set(() => {
        localStorage.setItem('favorite', JSON.stringify([]));
        return { favorite: [] };
      }),
  }));
})();
