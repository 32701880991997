import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import HomePage from "../pages/home/Home";
import DetailsPage from "../pages/details/DetailsPage";
import ShoppingCartPage from "../pages/shop/ShoppingCartPage";
import VerificationPage from "../pages/verifier/VerificationPage";
import FavorisPage from "../pages/favoris/FavorisPage";
import PaymentPage from "../pages/payment/PaymentPage";
import PayementConfirmationPage from "../pages/payementConfirmation/PayementConfirmationPage";
import AuthLayout from "../layouts/AuthLayout";
import LoginPage from "../pages/login/LoginPage";
import LoginVerifyPage from "../pages/login/LoginVerifyPage";
import Register from "../pages/login/Resgister";
import ServicePage from "../pages/service/ServicePage";
import PageNotFound from "../pages/404/PageNotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/details/:id",
        element: <DetailsPage />,
      },
      {
        path: "/shopping-cart",
        element: <ShoppingCartPage />,
      },
      {
        path: "/verify",
        element: <VerificationPage />,
      },
      {
        path: "/favoris",
        element: <FavorisPage />,
      },
      {
        path: "/payment",
        element: <PaymentPage />,
      },
      {
        path: "/payment/confirmation",
        element: <PayementConfirmationPage />,
      },
      {
        path: "services/:id",
        element: <ServicePage />,
      },
    ],
  },
  {
    path: "/auth/",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "login/verify",
        element: <LoginVerifyPage />,
      },
      {
        path: "register",
        element: <Register />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;
