import React from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/useFetch';
import Products from '../../components/products/Products';
import { API_URL } from '../../constants/api';
import { useCartStore } from '../../store/useCartStore';
import CartItem from './CartItem';
import { Link } from 'react-router-dom';
import { usePaymentStore } from '../../store/usePaymentStore';

function ShoppingCartPage() {
  const cart = useCartStore((state) => state.cart);
  const removeFromCart = useCartStore((state) => state.removeFromCart);
  const paymentMethod = usePaymentStore((state) => state.paymentMethod);
  const setPaymentMethod = usePaymentStore((state) => state.setPaymentMethod);

  const [increment, decrement] = useCartStore((state) => [
    state.incrementQuantity,
    state.decrementQuantity,
  ]);
  const { t } = useTranslation();
  const total = cart?.reduce((acc, item) => acc + item?.price * item?.quantity, 0);
  const { data: products } = useFetch(`${API_URL}/products`);

  return (
    <>
      <div className="shop">
        <div className="shopping">
          <h2>{t('shoppingCartPage.cartTitle')}</h2>
          <div className="panier-container">
            <div className="panier">
              <div className="item">
                <div className="item-header">
                  <p></p>
                  <p></p>
                  <p>{t('shoppingCartPage.product')}</p>
                  <p>{t('shoppingCartPage.price')}</p>
                  <p>{t('shoppingCartPage.quantity')}</p>
                  <p>{t('shoppingCartPage.total')}</p>
                </div>
                <div className="one_line"></div>
                {cart?.map((item) => (
                  <CartItem
                    item={item}
                    key={item?.id}
                    deleteItemCart={removeFromCart}
                    increment={increment}
                    decrement={decrement}
                  />
                ))}
              </div>
              <div className="actions">
                <div className="coupon">
                  <button>{t('shoppingCartPage.applyCoupon')}</button>
                </div>
                <input
                  className="promo-code"
                  type="text"
                  defaultValue={t('shoppingCartPage.promoCode')}
                />
                <div className="update">
                  <button>{t('shoppingCartPage.updateCart')}</button>
                </div>
              </div>
            </div>
            <div className="totaux">
              <div className="title">{t('shoppingCartPage.cartTotal')}</div>
              <div className="total">
                <div
                  style={{
                    color: paymentMethod ? '#137638' : '#96c2a7',
                  }}
                  className="total-title"
                >
                  {t('shoppingCartPage.totalAmount')}
                </div>
                <div className="total-price">XOF {total}</div>
              </div>
              <div className="livraison-container">
                <div className="livraison">
                  <input
                    type="radio"
                    className="livraison-input"
                    value="livreur"
                    name="methodLivraison"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label htmlFor="livraison" className="livraison-label">
                    {t('shoppingCartPage.standardDelivery')} : XOF {total}
                  </label>
                </div>
                <div className="livraison">
                  <input
                    type="radio"
                    className="livraison-input"
                    value="collect"
                    name="methodLivraison"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label htmlFor="livraison" className="livraison-label">
                    {t('shoppingCartPage.localCollection')} : XOF {total}
                  </label>
                </div>
              </div>
              <div className="description">{t('shoppingCartPage.shippingOptionsNote')}</div>
              <Link
                to={paymentMethod ? '/verify' : null}
                className={`caisse ${paymentMethod ? '' : 'disabled'}`}
              >
                {t('shoppingCartPage.proceedToCheckout')}
              </Link>
              <div className="achats">{t('shoppingCartPage.continueShopping')}</div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '7.06rem' }}>
          <Products products={products?.data} title={t('shoppingCartPage.title')} />
        </div>
      </div>
    </>
  );
}

export default ShoppingCartPage;
