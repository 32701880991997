import React from 'react';
import { GrClose } from 'react-icons/gr';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useCartStore } from '../../store/useCartStore';
import { Fragment } from 'react';
import { API_URL } from '../../constants/api';
function ListFavorisItems({ toggleFavorite, product }) {
  const { t } = useTranslation(); // Initialize useTranslation
  const cart = useCartStore((state) => state.cart);
  const existCart = cart.find((item) => item.id === product?.id);
  const addToCart = useCartStore((state) => state.addToCart);
  return (
    <Fragment>
      <tr>
        <td className="delete">
          <span onClick={() => toggleFavorite(product)}>
            <GrClose />
          </span>
        </td>
        <td className="product-name">
          <div>
            <img
              src={
                product?.product?.image?.data
                  ? `${API_URL.replace('/api', '')}${product?.image?.data[0]?.attributes?.url}`
                  : null
              }
              alt="product"
            />
            <span>{product?.name}</span>
          </div>
        </td>
        <td className="price">{product?.price} F CFA</td>
        {product?.availability ? (
          <td className="stock">
            <IoCheckmarkOutline /> {t('favoris.inStock')}
          </td>
        ) : (
          <td className={`out_of_stock`}>
            <IoClose /> {t('favoris.outOfStock')}
          </td>
        )}
        {!existCart ? (
          <td className="btn">
            <button onClick={() => addToCart({ ...product, quantity: 1 })}>
              {t('favoris.addToCart')}
            </button>
          </td>
        ) : (
          <td className="btn"></td>
        )}
      </tr>
      <tr className="line"></tr>
    </Fragment>
  );
}

export default ListFavorisItems;
