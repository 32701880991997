import React from 'react';
import Gif from '../../assets/images/giphy.gif';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="not_found_page">
      <div className="gif">
        <img src={Gif} alt="gif_ing" />
      </div>
      <div className="content">
        <h1 className="main-heading">This page is gone.</h1>
        <p>...maybe the page you're looking for is not found or never existed.</p>
        <Link to="/">
          <button>
            Back to home <i className="far fa-hand-point-right"></i>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
