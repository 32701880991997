import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <div className="verification_page__title">
      <div className="verification_page__title__back">
        <span>
          <IoMdArrowRoundBack />
        </span>
        <p>{t("paymentHeader.verify")}</p>
      </div>
      <div className="verification_page__title__identify">
        <p>
          {t("paymentHeader.alreadyCustomer")}{" "}
          <span>{t("paymentHeader.clickHere")}</span>
        </p>
      </div>
    </div>
  );
}

export default Header;
