import React, { useEffect } from 'react';
import { API_URL } from '../../constants/api';
import useFetch from '../../hooks/useFetch';
import { useAppStore } from '../../store/useAppStore';

const CategoryDropdown = ({ toggle, menuContainerRef }) => {
  const { data: categories, error } = useFetch(`${API_URL}/categories`);
  const productFiltre = useAppStore((state) => state.productFiltre);
  const setProductFiltre = useAppStore((state) => state.setProductFiltre);
  const limit = 7;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuContainerRef.current && !menuContainerRef.current.contains(event.target)) {
        toggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuContainerRef, toggle]);

  return (
    <ul onClick={(e) => e.stopPropagation()} className="products_dropdown">
      {categories &&
        !error &&
        categories?.data?.slice(0, limit).map((category) => {
          return (
            <li
              key={category.id}
              className="products_dropdown__item"
              onClick={() => {
                toggle();
                document.querySelector('#category_filtre')?.scrollIntoView({ behavior: 'smooth' });
                setProductFiltre({
                  ...productFiltre,
                  category: productFiltre.category === category.id ? null : category.id,
                });
              }}
            >
              {category?.attributes.name}
            </li>
          );
        })}
      {!categories &&
        !error &&
        Array.from({ length: limit }).map((_, i) => (
          <li key={i} className="products_dropdown__item products_dropdown__item--loader">
            <div></div>
          </li>
        ))}
    </ul>
  );
};

export default CategoryDropdown;
