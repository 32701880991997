import React, { useEffect } from 'react';
import { usePaymentStore } from '../../store/usePaymentStore';
import { fetchApi } from '../../apis/fetch';
import { useCartStore } from '../../store/useCartStore';
import { API_URL } from '../../constants/api';
import { useAuthStore } from '../../store/auth';
import { useNavigate } from 'react-router-dom';

const PayementConfirmationPage = () => {
  const navigate = useNavigate();
  const reset = usePaymentStore((state) => state.reset);
  const resetCart = useCartStore((state) => state.clearCart);
  const paymentInfos = usePaymentStore((state) => state.paymentInfos);
  const shippingMethod = usePaymentStore((state) => state.paymentMethod);
  const customer = useAuthStore((state) => state.costumer);
  const state = useAuthStore((state) => state);
  const user = useAuthStore((state) => state.user);
  const loadingUser = useAuthStore((state) => state.loading);
  const cart = useCartStore((state) => state.cart);

  const totalPrice = cart.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
  console.log(state);

  console.log({
    customer,
    user,
    shippingMethod,
    paymentInfos,
    cart,
    totalPrice,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loadingUser && !user && !customer) return navigate('/');
    if (!loadingUser && user && customer && shippingMethod && paymentInfos) {
      validePayment().then(() => {
        reset();
        resetCart();
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, customer, shippingMethod, paymentInfos]);

  async function validePayment() {
    try {
      const res = await Promise.all([
        fetchApi(`${API_URL}/orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: customer.id,
            products: cart,
            total: totalPrice,
            deposit: 1,
            rest: 1,
            shipping_method: shippingMethod,
            payment_method: paymentInfos.payment,
          }),
        }),
        fetchApi(`${API_URL.replace('/shs', '')}/paydunya-api/wave/make`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: 'Khadim Fall',
            email: 'khadimtitech@gmail.com',
            phone: 771904645,
            total_amount: totalPrice,
          }),
        }),
      ]);

      const url = res[1]?.url;
      if (url) return (window.location = url);
    } catch (error) {
      console.log(error);
      alert('Une erreur est survenue');
    }
  }

  return (
    <div className="payment_confirmation">
      <span className="loader"></span>
      <h2>Veillez patienter...</h2>
    </div>
  );
};

export default PayementConfirmationPage;
