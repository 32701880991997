import React from "react";
import { useTranslation } from "react-i18next"; // Add the import for useTranslation
import ListFavoris from "./ListFavoris";
import Footing from "./Footing";
import useFetch from '../../hooks/useFetch';
import { API_URL } from "../../constants/api";
import Products from "../../components/products/Products";
import { useFavoriteStore } from "../../store/useFavoriteStore";

function FavorisPage() {
  const { t } = useTranslation();
  const { data: products } = useFetch(`${API_URL}/products`);
  const favorite = useFavoriteStore((state) => state.favorite);

  return (
    <div className="favoris_page">
      <h3>{t("favorisPage.wishlist")}</h3>
      <ListFavoris products={favorite} />
      <Footing />
      <Products products={products?.data} title={t("interestedProduct.title")} />
    </div>
  );
}

export default FavorisPage;
