import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { GoTrash } from 'react-icons/go';
import { useCartStore } from '../../store/useCartStore';

function CommandeItem({ product }) {
  const { t } = useTranslation();
  const incrementQuantity = useCartStore((state) => state.incrementQuantity);
  const decrementQuantity = useCartStore((state) => state.decrementQuantity);
  const removeFromCart = useCartStore((state) => state.removeFromCart)

  return (
    <div className="verification_page__info_customer__commande__products__product">
      <div className="verification_page__info_customer__commande__products__product__header">
        <p>{product?.name}</p>
        <span onClick={() => removeFromCart(product)} style={{ cursor: 'pointer' }}>
          <GoTrash />
        </span>
      </div>
      <div className="verification_page__info_customer__commande__products__product__body">
        <div className="verification_page__info_customer__commande__products__product__body__image">
          <img width={62} src={product.image_links[0]} alt="product" />
        </div>
        <div className="verification_page__info_customer__commande__products__product__body__info">
          {product.properties.map((property) => (
            <p>
              <FaCheck color={'#12a05c'} />
              &nbsp; {property}
            </p>
          ))}
        </div>
        <div className="verification_page__info_customer__commande__products__product__body__quantity">
          <p>{t('commandeItem.quantity')}</p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => decrementQuantity(product)}
            >
              <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#88B825" />
              <path
                d="M16.4051 12.9H7.60511C7.10511 12.9 6.70511 12.5 6.70511 12C6.70511 11.5 7.10511 11.1 7.60511 11.1H16.4051C16.9051 11.1 17.3051 11.5 17.3051 12C17.3051 12.5 16.9051 12.9 16.4051 12.9Z"
                fill="#88B825"
              />
            </svg>
            <span>{product?.quantity}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => incrementQuantity(product)}
            >
              <rect x="0.5" y="0.341553" width="23" height="23" rx="3.5" stroke="#88B825" />
              <path
                d="M17.3051 11.9416C17.3051 12.4416 16.9051 12.8416 16.4051 12.8416H12.8051V16.2416C12.8051 16.7416 12.4051 17.1416 11.9051 17.1416C11.4051 17.1416 11.0051 16.7416 11.0051 16.2416V12.8416H7.60511C7.10511 12.8416 6.70511 12.4416 6.70511 11.9416C6.70511 11.4416 7.10511 11.0416 7.60511 11.0416H11.0051V7.44157C11.0051 6.94156 11.4051 6.54156 11.9051 6.54156C12.4051 6.54156 12.8051 6.94156 12.8051 7.44157V11.0416H16.4051C16.9051 11.0416 17.3051 11.4416 17.3051 11.9416Z"
                fill="#88B825"
              />
            </svg>
          </p>
        </div>
      </div>
      <div className="verification_page__info_customer__commande__products__product__price">
        <p>XOF {product?.price * product?.quantity}</p>
      </div>
      <div className="verification_page__info_customer__commande__products__product__line">
        <div></div>
      </div>
    </div>
  );
}

export default CommandeItem;
