import React from 'react';
import useFetch from '../../hooks/useFetch';
import { API_URL } from '../../constants/api';
import { useAppStore } from '../../store/useAppStore';

const Categories = () => {
  const setFilterCategory = useAppStore((state) => state.setProductFiltre);
  const productFiltre = useAppStore((state) => state.productFiltre);
  const { data: categories, error } = useFetch(`${API_URL}/categories`);

  return (
    <div id="category_filtre" className="filtre no-scroll-bar">
      {categories &&
        !error &&
        categories.data.map((category) => (
          <button
            key={category.id}
            onClick={() =>
              setFilterCategory({
                ...productFiltre,
                category: productFiltre.category === category.id ? null : category.id,
              })
            }
            className={productFiltre.category === category.id ? 'active' : ''}
          >
            {category.attributes.name}
          </button>
        ))}
      {!categories &&
        !error &&
        Array.from({ length: 7 }).map((_, index) => (
          <button key={`l-${index}`} className="loader"></button>
        ))}
      {error && (
        <div className="center">
          <p className="error">Une erreur est survenue</p>
        </div>
      )}
    </div>
  );
};

export default Categories;
