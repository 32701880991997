import React from 'react';
import { useTranslation } from 'react-i18next';
import CommandeItem from './CommandeItem';
import { useCartStore } from '../../store/useCartStore';

function CommandePage() {
  const { t } = useTranslation(); // Initialisez useTranslation
  const cart = useCartStore((state) => state.cart);

  const total = cart?.reduce((acc, item) => acc + item?.price * item?.quantity, 0);

  return (
    <div className="verification_page__info_customer__commande">
      <div className="verification_page__info_customer__commande__header">
        <p>{t('commandePage.yourOrder')}</p>
      </div>
      <div className="verification_page__info_customer__commande__line">
        <div></div>
      </div>
      <div className="verification_page__info_customer__commande__products">
        {cart?.map((product) => (
          <CommandeItem key={product?.id} product={product} />
        ))}
      </div>
      <div className="verification_page__info_customer__commande__total">
        <p>{t('commandePage.total')}</p>
        <p>XOF {total}</p>
      </div>
    </div>
  );
}

export default CommandePage;
