import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import React, { Fragment, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../configs/firebase';
import { useAuthStore } from '../../store/auth';
import { isValidNumber } from 'libphonenumber-js';

const LoginPage = () => {
  const navigate = useNavigate();
  const setPhoneNumber = useAuthStore((state) => state.setPhoneNumber);
  const [number, setNumber] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    return async () => {
      await window.recaptchaVerifier?.clear();
      window.recaptchaVerifier = null;
    };
  }, []);

  const onSubmit = () => {
    if (!isValidNumber(`+${number}`)) return setError('Numéro de téléphone invalide');
    setError('');
    setupRecaptcha(auth);
    signInWithPhoneNumber(auth, `+${number}`, window.recaptchaVerifier)
      .then((res) => {
        window.confirmationResult = res;
        setPhoneNumber(`+${number}`);
        setNumber(null);
        navigate('/auth/login/verify');
      })
      .catch((error) => {
        console.log('Login error :', error);
        setError("Une erreur s'est produite! Veuillez réessayer plus tard.");
      });
  };

  function setupRecaptcha(auth) {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: function (response) {
        console.log('Recaptcha resolved', response);
      },
      'expired-callback': function () {
        setError('Recaptcha expiré. Veuillez recharger la page.');
      },
    });
  }

  return (
    <Fragment>
      <div className="login_page__form__title">
        <h2>Bienvenue chez SHS</h2>
        <p>
          Saisissez votre numéro de téléphone pour <br /> vous connecter ou créer un compte shs.
        </p>
      </div>
      <div className="form-group">
        <PhoneInput
          inputClass="phoneInput"
          country={'sn'}
          value={number}
          onChange={(phone) => setNumber(phone)}
        />
      </div>
      {error && <div className="form-group error center">{error}</div>}
      <div className="login_page__form__btn">
        <button type="button" onClick={onSubmit}>
          Continuer
        </button>
      </div>
      <div className="center padding">
        <div id="recaptcha-container"></div>
      </div>
    </Fragment>
  );
};

export default LoginPage;
