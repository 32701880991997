import { create } from 'zustand';


export const useAppStore = create((set) => ({
  productFiltre: {
    category: null,
  },

  setProductFiltre: (productFiltre) => set(() => ({ productFiltre })),
}));
