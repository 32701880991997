import { create } from 'zustand';

export const useCartStore = (() => {
  const cartData = localStorage.getItem('cart') ?? '[]';

  return create((set) => ({
    cart: JSON.parse(cartData),
    addToCart: (product) =>
      set((state) => {
        let cart = [...state.cart, product];
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    removeFromCart: (product) =>
      set((state) => {
        const cart = state.cart.filter((p) => p.id !== product.id);
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    decrementQuantity: (product) =>
      set((state) => {
        const cart = [...state.cart];
        const index = cart.findIndex((p) => p.id === product.id);
        if (index !== -1 && cart[index].quantity > 1) cart[index].quantity -= 1;
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    incrementQuantity: (product) =>
      set((state) => {
        const cart = [...state.cart];
        const index = cart.findIndex((p) => p.id === product.id);
        if (index !== -1 && cart[index].quantity < 99) cart[index].quantity += 1;
        localStorage.setItem('cart', JSON.stringify(cart));
        return { cart };
      }),
    clearCart: () =>
      set(() => {
        localStorage.setItem('cart', JSON.stringify([]));
        return { cart: [] };
      }),
  }));
})();
