import GambiaIcon from '../assets/images/icons/icon gambia.png';
import MauritaniaIcon from '../assets/images/icons/icon muritania.png';
import SenegalIcon from '../assets/images/icons/icon senegal.svg';
import Fr from '../locales/fr/translation.json';
import En from '../locales/en/translation.json';
import Ar from '../locales/ar/translation.json';

export const countryFlags = {
  fr: SenegalIcon,
  en: GambiaIcon,
  ar: MauritaniaIcon,
};

export const resources = {
  en: {
    translation: En,
  },
  fr: {
    translation: Fr,
  },
  ar: {
    translation: Ar,
  },
};
