import { create } from 'zustand'

export const useAuthStore = create((set) => ({
  user: null,
  costumer: null,
  confirmResult: null,
  phoneNumber: null,
  loading: true,
  setLoading: (loading) => set(() => ({ loading })),
  setCostumer: (costumer) => set(() => ({ costumer })),
  setConfirmResult: (confirmResult) => set(() => ({ confirmResult })),
  setPhoneNumber: (phoneNumber) => set(() => ({ phoneNumber })),
  login: (user) => set(() => ({ user })),
  logout: () => set(() => ({ user: null })),
}))