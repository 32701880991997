import React from 'react';
import PaymentSection from './PaymentSection';
import CommandePage from '../../components/your_order/CommandePage';
import HeaderPayment from './HeaderPayment';

function PaymentPage() {
  return (
    <div className="payment_page">
      <HeaderPayment />
      <div className="payment_page__container">
        <PaymentSection />
        <CommandePage />
      </div>
    </div>
  );
}

export default PaymentPage;
