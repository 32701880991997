import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguadDetector from 'i18next-browser-languagedetector';
import { resources } from './libs/i118n';

i18n
  .use(LanguadDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLang: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      convertDetectedLanguage: (lng) => lng.split('-')[0].toLowerCase(),
    },
  });

export default i18n;
