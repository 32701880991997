import React from 'react';
import Product from './Product';

function Products({ products, title }) {
  const handlePrevClick = () => {};

  const handleNextClick = () => {};

  return (
    <div className="products" style={{ padding: '0px', marginBottom: '2.94rem' }}>
      <div className="toolbar">
        <h3 style={{ marginTop: '0px' }}>{title}</h3>
        <div className="best_sale-toolbar-action">
          <button
            className="best_sale-toolbar-action-prev"
            onClick={handlePrevClick}
            style={{ backgroundColor: '#137638' }}
          >
            {'\u276E'}
          </button>
          <button
            className="best_sale-toolbar-action-next"
            onClick={handleNextClick}
            style={{ backgroundColor: '#137638' }}
          >
            {'\u276F'}
          </button>
        </div>
      </div>
      <div className="products-list no-scroll-bar">
        {products?.map((product) => (
          <Product product={product?.attributes} id={product?.id} key={product?.id} />
        ))}
      </div>
    </div>
  );
}

export default Products;
