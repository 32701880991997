import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyD96BKf53lauFzq_RB2ImpdwB02rFEJOZQ',
  authDomain: 'shs-backend.firebaseapp.com',
  projectId: 'shs-backend',
  storageBucket: 'shs-backend.appspot.com',
  messagingSenderId: '384587816782',
  appId: '1:384587816782:web:9a3f0fdc2ff2e2afc9481f',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
