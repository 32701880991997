import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Ad from '../ad/Ad';
import Product from '../product/Product';
import { API_URL } from '../../constants/api';

const BestSale = ({ bestProducts, errors }) => {
  let navigate = useNavigate();
  const bestProduct = bestProducts?.length > 0 ? bestProducts[0] : null;
  const { t } = useTranslation();
  const [page] = useState(1);
  const perPage = 4;

  //const totalPage = Math.ceil(bestProducts?.length / perPage);
  const paginateItems = bestProducts?.slice(0, page * perPage);

  return (
    <div className="best_sale">
      <div className="best_sale-ads">
        <Ad />
      </div>
      <div>
        <div className="best_sale-toolbar">
          <h3>{t('bestSale.bestSellers')}</h3>
          <div className="best_sale-toolbar-action">
            <button className="best_sale-toolbar-action-prev">&#x276E;</button>
            <button className="best_sale-toolbar-action-next">&#x276F;</button>
          </div>
        </div>
        <div className="best_sale-products">
          <div className="best_sale-products-big_item">
            <h4>{bestProduct?.attributes?.name}</h4>
            <div className="best_sale-products-big_item-img">
              {paginateItems?.map((product) => {
                console.log("dsdd",product);
                return (
                  <img
                    key={product.id}
                    src={
                      product?.attributes?.image?.data
                        ? `${API_URL.replace('/api', '')}${product?.attributes?.image?.data[0]?.attributes?.url}`
                        : null
                    }
                    alt=""
                    onClick={() => {
                      navigate(`/details/${product.id}`);
                    }}
                  />
                );
              })}
            </div>
            <div className="best_sale-products-big_item-btn">
              <Link to={`/details/${bestProduct?.id}`}>{t('bestSale.buyNow')}</Link>
            </div>
          </div>
          <div className="best_sale-products-items">
            {bestProduct &&
              !errors &&
              paginateItems?.map((product) => (
                <Product product={product?.attributes} id={product?.id} key={product.id} />
              ))}

            {!bestProduct &&
              !errors &&
              Array.from(Array(4).keys()).map((product) => {
                return (
                  <div key={product} className="product loader">
                    <div className="image"></div>
                    <div className="text">
                      <div className="title"></div>
                      <div className="price">
                        <div className="price-loader"></div>
                        <div className="price-loader"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!bestProduct && errors && <p>{t('bestSale.errorLoading')}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestSale;
