import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/api';

function Product({ product, id }) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="product">
      <div className="top">
        <div className={`${product?.availability ? 'stock' : 'out_of_stock'}`}>
          {product?.availability
            ? t('interestedProduct.stockStatus.inStock')
            : t('interestedProduct.stockStatus.outOfStock')}
        </div>
      </div>
      <div
        className="image"
        onClick={() => {
          navigate(`/details/${id}`);
        }}
      >
        <img
          src={
            product?.product?.image?.data
              ? `${API_URL.replace('/api', '')}${product?.image?.data[0]?.attributes?.url}`
              : null
          }
          alt=""
        />
      </div>
      <div className="title">
        <div>{product?.category_name}</div>
        <div>
          <Link to={`/details/${id}`}>{product?.name}</Link>
        </div>
      </div>
      <hr />
      <div className="price">
        <div>
          <div>
            {t('interestedProduct.priceLabel')} {product?.price}
          </div>
          <div>
            {t('interestedProduct.priceLabel')} {product?.price}
          </div>
        </div>
        <div>
          <button>{t('interestedProduct.addToCartButton')}</button>
        </div>
      </div>
    </div>
  );
}

export default Product;
