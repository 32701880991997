import React from "react";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import { useTranslation } from "react-i18next"; // Add the import for useTranslation

function Footing() {
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div className="footing">
      <p className="security">
        <MdOutlineVerifiedUser />
        {t("footing.security")}
      </p>
      <p className="line-v"></p>
      <p className="certify">
        <RiVerifiedBadgeLine />
        {t("footing.certify")}
      </p>
      <p className="line-v"></p>
      <p className="delivery">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="17"
          viewBox="0 0 37 17"
          fill="none"
        >
          <path
            d="M34.0918 13.7125C34.428 13.7039 34.6095 13.0901 34.6095 13.0901C34.6095 13.0901 35.8275 13.1668 36.0181 12.3672C36.0431 12.2612 36.1935 3.96892 35.0869 1.66626C35.0427 1.57459 34.9759 1.49566 34.8928 1.43686C34.8097 1.37806 34.7131 1.34132 34.6119 1.33009C32.7593 1.11084 22.7764 0.624857 14.9695 1.51888C14.9695 1.51888 12.1431 2.25213 7.20468 5.70643C7.20468 5.70643 3.85513 7.0767 2.40873 8.06573C0.962334 9.05476 1.00009 10.9591 1.00009 10.9591C1.00009 10.9591 1.15235 12.7862 1.45685 13.3574C1.50313 13.4445 1.69924 14.1077 3.02931 14.1369"
            fill="white"
          />
          <path
            d="M36.0383 11.2508H30.3635V11.7393C30.3635 11.9989 30.4145 12.256 30.5139 12.4958C30.6132 12.7357 30.7588 12.9537 30.9424 13.1373C31.126 13.3208 31.3439 13.4665 31.5838 13.5658C31.8237 13.6651 32.0808 13.7162 32.3404 13.7161H34.232C34.8318 13.7161 35.3994 13.4433 35.774 12.9755L36.3227 12.291L36.0383 11.2508ZM7.36371 11.7277H1.68835V13.2733L2.88445 14.1369H6.50562L7.64751 12.8148L7.36371 11.7277Z"
            fill="#E5EECB"
          />
          <path
            d="M7.17066 15.383C8.17969 15.383 8.99768 14.565 8.99768 13.5559C8.99768 12.5469 8.17969 11.7289 7.17066 11.7289C6.16162 11.7289 5.34363 12.5469 5.34363 13.5559C5.34363 14.565 6.16162 15.383 7.17066 15.383Z"
            fill="#E5EECB"
          />
          <path
            d="M30.3636 15.383C31.3727 15.383 32.1907 14.565 32.1907 13.5559C32.1907 12.5469 31.3727 11.7289 30.3636 11.7289C29.3546 11.7289 28.5366 12.5469 28.5366 13.5559C28.5366 14.565 29.3546 15.383 30.3636 15.383Z"
            fill="#E5EECB"
          />
          <path
            d="M17.3927 2.82582V6.88608L8.76489 7.12725C8.76489 7.12725 12.9774 2.31852 17.3927 2.82582Z"
            fill="#E5EECB"
          />
          <path
            d="M5.51593 9.30445C5.51593 9.30445 5.5677 8.64793 4.73031 8.57181C3.89292 8.49568 2.35639 9.30445 2.27113 9.52369C2.18526 9.74293 2.05798 10.3958 2.44165 10.5133C2.82594 10.6303 5.51593 9.30445 5.51593 9.30445Z"
            fill="#E5EECB"
          />
          <path
            d="M4.93721 9.25085C4.93721 9.25085 3.74051 10.2618 2.75879 10.4189L4.93721 9.25085Z"
            fill="#E5EECB"
          />
          <path
            d="M10.6433 13.5377C15.6792 13.4932 21.2492 13.3769 26.0719 13.2734"
            stroke="#137638"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.17066 15.383C8.17969 15.383 8.99768 14.565 8.99768 13.5559C8.99768 12.5469 8.17969 11.7289 7.17066 11.7289C6.16162 11.7289 5.34363 12.5469 5.34363 13.5559C5.34363 14.565 6.16162 15.383 7.17066 15.383Z"
            stroke="#137638"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M30.3633 15.383C31.3723 15.383 32.1903 14.565 32.1903 13.5559C32.1903 12.5469 31.3723 11.7289 30.3633 11.7289C29.3542 11.7289 28.5363 12.5469 28.5363 13.5559C28.5363 14.565 29.3542 15.383 30.3633 15.383Z"
            stroke="#137638"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M17.3928 3.43481V6.88607L9.22172 7.44453M11.4038 11.3519L26.629 10.7429M4.93734 9.25085C4.93734 9.25085 3.74063 10.2618 2.75891 10.4189"
            stroke="#137638"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.0918 13.1035L34.6095 13.0901C34.6095 13.0901 35.8275 13.1668 36.0181 12.3672C36.0431 12.2612 36.1935 3.96893 35.0869 1.66626C35.0427 1.57459 34.9759 1.49566 34.8928 1.43686C34.8097 1.37806 34.7131 1.34132 34.6119 1.33009C32.7593 1.11084 22.7764 0.624856 14.9695 1.51888C14.9695 1.51888 12.1431 2.25213 7.20468 5.70643C7.20468 5.70643 3.85513 7.0767 2.40873 8.06573C0.962334 9.05476 1.00009 10.9591 1.00009 10.9591C1.00009 10.9591 1.15235 12.7862 1.45685 13.3574C1.50313 13.4445 1.69924 13.4987 3.02931 13.5279"
            stroke="#137638"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {t("footing.delivery")}
      </p>
    </div>
  );
}

export default Footing;
