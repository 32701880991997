import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Add the import for useTranslation
import DisplayIcon from '../../assets/images/icons/icon affichage.svg';
import DisplayBarIcon from '../../assets/images/icons/icon barre affichage.svg';
import Product from '../../components/product/Product';
import { useAppStore } from '../../store/useAppStore';

const Products = ({ products, errors, filtered }) => {
  const { category } = useAppStore((state) => state.productFiltre);
  const { t } = useTranslation();
  const [paginateItems, setPaginateItems] = useState([]);
  const perPage = 8;
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (products) {
      setPaginateItems(() =>
        products
          .filter((p) => !category || p.category_id === category || !filtered)
          .slice(0, page * perPage),
      );
      setTotalPages(Math.ceil(products.length / perPage));
    }
    return () => {};
  }, [products, page, category, filtered]);

  useEffect(() => {
    setPage(1);
    return () => {};
  }, [category]);

  function loadMore() {
    if (page < totalPages) {
      setPage(page + 1);
    }
  }

  return (
    <div className="products">
      <div className="toolbar">
        <h3>{t('products.latestProducts')}</h3>
        <div className="action">
          <img src={DisplayIcon} alt="" />
          <img src={DisplayBarIcon} alt="" />
        </div>
      </div>
      <div className="products-list no-scroll-bar">
        {products &&
          !errors &&
          paginateItems?.map((product) => (
            <Product product={product?.attributes} id={product?.id} key={product.id} />
          ))}

        {!products &&
          !errors &&
          Array.from(Array(8).keys()).map((product) => {
            return (
              <div key={product} className="product loader">
                <div className="image"></div>
                <div className="text">
                  <div className="title"></div>
                  <div className="price">
                    <div className="price-loader"></div>
                    <div className="price-loader"></div>
                  </div>
                </div>
              </div>
            );
          })}

        {!products && errors && <p>{t('products.errorLoading')}</p>}
      </div>
      <div className="more">
        <button disabled={page >= totalPages} onClick={loadMore}>
          {t('products.viewMore')}
        </button>
      </div>
    </div>
  );
};

export default Products;
