import { create } from 'zustand';

export const usePaymentStore = create((set, get) => ({
  clientInfos: null,
  paymentInfos: null,
  paymentMethod: null,
  setClientInfos: (clientInfos) => set({ clientInfos }),
  setPaymentInfos: (paymentInfos) => set({ paymentInfos }),
  setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
  reset: () => set({ clientInfos: null, paymentInfos: null , paymentMethod: null}),
}));
