import React from 'react';
import HeartIcon from '../../assets/images/icons/icon heart.svg';
import HomeIcon from '../../assets/images/icons/icon home.svg';
import ProductIcon from '../../assets/images/icons/icon produits.svg';

const BottomNavBar = () => {
  return (
    <div className="bottom-nav-bar">
      <a href="/">
        <img src={ProductIcon} alt="" />
      </a>
      <a href="/">
        <img src={HomeIcon} alt="" />
      </a>
      <a href="/">
        <svg fill="#88B825" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
          <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
        </svg>
      </a>
      <a href="/">
        <img src={HeartIcon} alt="" />
      </a>
      <a href="/">
        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="46" viewBox="0 0 47 46" fill="none">
          <path
            d="M18.7319 26.944L31.4569 24.5816V17.478H16.1819"
            stroke="#88B825"
            strokeWidth="1.91"
            strokeMiterlimit="10"
          />
          <path
            d="M13.1565 15.1157H15.5482L18.7232 26.944L17.3398 29.0007C17.2074 29.2021 17.1379 29.4377 17.1398 29.6781C17.1398 29.9979 17.268 30.3046 17.4962 30.5308C17.7244 30.757 18.0338 30.884 18.3565 30.884H28.2732"
            stroke="#88B825"
            strokeWidth="1.91"
            strokeMiterlimit="10"
          />
          <path
            d="M28.2734 32.462C28.7106 32.462 29.065 32.1107 29.065 31.6773C29.065 31.2439 28.7106 30.8926 28.2734 30.8926C27.8361 30.8926 27.4817 31.2439 27.4817 31.6773C27.4817 32.1107 27.8361 32.462 28.2734 32.462Z"
            stroke="#88B825"
            strokeWidth="1.91"
            strokeMiterlimit="10"
          />
          <path
            d="M20.3149 32.462C20.7521 32.462 21.1065 32.1107 21.1065 31.6773C21.1065 31.2439 20.7521 30.8926 20.3149 30.8926C19.8776 30.8926 19.5232 31.2439 19.5232 31.6773C19.5232 32.1107 19.8776 32.462 20.3149 32.462Z"
            stroke="#88B825"
            strokeWidth="1.91"
            strokeMiterlimit="10"
          />
        </svg>
      </a>
    </div>
  );
};

export default BottomNavBar;
