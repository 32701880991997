import React from 'react';
import { GrClose } from 'react-icons/gr';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useCartStore } from '../../store/useCartStore';
import { API_URL } from '../../constants/api';

function MobileItems({ product }) {
  const { t } = useTranslation(); // Initialize useTranslation
  const cart = useCartStore((state) => state.cart);
  const existCart = cart.find((item) => item.id === product?.id);
  const addToCart = useCartStore((state) => state.addToCart);
  return (
    <div className="mobile-list">
      <div className="delete">
        <GrClose />
      </div>
      <div className="image">
        <img
          src={
            product?.product?.image?.data
              ? `${API_URL.replace('/api', '')}${product?.image?.data[0]?.attributes?.url}`
              : null
          }
          alt="product"
        />
      </div>
      <div className="line"></div>
      <div className="name">
        <p>{t('favoris.productName')}</p>
        <p>{product?.name}</p>
      </div>
      <div className="line"></div>
      <div className="price">
        <p>{t('favoris.unitPrice')}</p>
        <p>{product?.price} F CFA</p>
      </div>
      <div className="line"></div>
      <div className="stock">
        <p>{t('favoris.stockStatus')}</p>
        {product?.availability ? (
          <p className="in_stock">
            <IoCheckmarkOutline /> {t('favoris.inStock')}
          </p>
        ) : (
          <p className={`out_of_stock`}>
            <IoClose /> {t('favoris.outOfStock')}
          </p>
        )}
      </div>
      <div className="line"></div>
      {!existCart ? (
        <div className="btn">
          <button onClick={() => addToCart({ ...product, quantity: 1 })}>
            {t('favoris.addToCart')}
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default MobileItems;
