import React from "react";
import { useTranslation } from "react-i18next"; // Add the import for useTranslation

function Description({ product }) {
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div className="description">
      <div className="title">
        <h3>{t("description.description")}</h3>
        <p>{t("description.additionalInfo")}</p>
      </div>
      <div className="description_text">{product?.description}</div>
    </div>
  );
}

export default Description;
