import React, { useEffect } from "react";
import useFetch from '../../hooks/useFetch';
import BestSale from "../../components/best-sale/BestSale";
import { API_URL } from "../../constants/api";
import Categories from "./Categories";
import Contact from "./Contact";
import Event from "./Event";
import Hero from "./Hero";
import Products from "./Products";
import Testimonial from "./Testimonial";
import { Link } from "react-router-dom";

const HomePage = () => {
  const { data: lastProducts, error: errorLastProducts } = useFetch(
    `${API_URL}/products?populate=image&pagination[pageSize]=100`
  );
  const { data: services, error: errorServices } = useFetch(
    `${API_URL}/services`
  );

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="home">
      <Hero />
      <Categories />
      <div style={{ backgroundColor: "#fff" }}>
        <Products products={lastProducts?.data} errors={errorLastProducts} filtered />
      </div>
      <div className="card_infos">
        {services &&
          !errorServices &&
          services?.data?.map((service) => (
            <div key={service?.id}>
              <img src={service?.image_link} alt="" />
              <Link
                to={`/services/${service?.id}`}
                style={{ textDecoration: "none", color: "#137638" }}
              >
                {service?.name}
              </Link>
            </div>
          ))}
      </div>
      <BestSale bestProducts={lastProducts?.data} errors={errorLastProducts} />
      <Testimonial />
      <div style={{ backgroundColor: "#F7F9EF", marginTop: "50px" }}>
        <Products products={lastProducts?.data} errors={errorLastProducts} />
      </div>
      <Event />
      <Contact />
    </div>
  );
};

export default HomePage;
