import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API_URL } from "../../constants/api.js";
import useFetch from '../../hooks/useFetch.js';
import { useParams } from "react-router-dom";
import { useAuthStore } from "../../store/auth.js";
import rv from "../../assets/images/rv.jpg";

function ServicePage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: service } = useFetch(`${API_URL}/services/${id}`);
  const { data: shops } = useFetch(`${API_URL}/shops`);
  const auth_id = useAuthStore((state) => state.user?.uid);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const sendData = async (data) => {
    const selectedShop = shops?.data?.find((shop) => shop?.id === data.shop_id);
    const date = new Date();
    const today =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    const hour = new Date();
    const now = hour.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    if (data.service_day < today) {
      setErrorMessage("la date choisi est inferieur a la date d'aujourd'hui");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    } else if (data.service_day === today && data.service_hour < now) {
      setErrorMessage("l'heure choisi est inferieur a l'heure d'aujourd'hui");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    if (selectedShop?.opening_hours?.weekend?.start) {
      const weekendStart = selectedShop?.opening_hours?.weekend?.start;
      const weekendEnd = selectedShop?.opening_hours?.weekend?.end;
      const weekStart = selectedShop?.opening_hours?.week?.start;
      const weekEnd = selectedShop?.opening_hours?.week?.end;
      const serviceHour = data.service_hour.split(":")[0];
      if (weekStart <= serviceHour && serviceHour <= weekEnd) {
        //le shop est ouvert
      } else if (weekendStart <= serviceHour && serviceHour <= weekendEnd) {
        //le shop est ouvert
      } else {
        setErrorMessage("le shop est fermé a cette heure");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return;
      }
    }
    const response = await fetch(`${API_URL}/bookings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        shop_name: selectedShop?.name,
        service_id: service?.data?.id,
        service_type: service?.data?.name,
        auth_id: auth_id,
      }),
    });
    if (response.ok) {
      setSuccessMessage("Votre demande a été envoyé avec succès");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      reset();
    }
  };
  return (
    <div className="services">
      <div className="services__header">
        <h1>{service?.name}</h1>
      </div>
      <div className="services__description">
        <p>Descriptions:</p>
        <p>{service?.description}</p>
      </div>
      <div className="services__form">
        <div className="contact">
          <div className="contact-form">
            <h1>Prendre rendez-vous pour {service?.name}</h1>
            <form
              onSubmit={handleSubmit((data) => {
                sendData(data);
              })}
            >
              <div>
                <div className="contact-form-input">
                  <label htmlFor="firstName">{t("contact.firstName")}</label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder={t("contact.firstNamePlaceholder")}
                    {...register("fname", {
                      required: true,
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "First name must be letters only",
                      },
                    })}
                  />
                  {errors?.fname && (
                    <span className="error">{errors?.fname?.message}</span>
                  )}
                </div>
                <div className="contact-form-input">
                  <label htmlFor="lastName">{t("contact.lastName")}</label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder={t("contact.lastNamePlaceholder")}
                    {...register("lname", {
                      required: true,
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Last name must be letters only",
                      },
                    })}
                  />
                  {errors?.lname && (
                    <span className="error">{errors?.lname?.message}</span>
                  )}
                </div>
              </div>
              <div>
                <div className="contact-form-input">
                  <label htmlFor="email">{t("contact.email")}</label>
                  <input
                    type="email"
                    id="email"
                    placeholder={t("contact.emailPlaceholder")}
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors?.email && (
                    <span className="error">{errors?.email?.message}</span>
                  )}
                </div>
                <div className="contact-form-input">
                  <label htmlFor="address">{t("contact.address")}</label>
                  <input
                    type="text"
                    id="address"
                    placeholder={t("contact.addressPlaceholder")}
                    {...register("address", {
                      required: true,
                      pattern: {
                        value: /^[A-Za-z0-9 ]+$/i,
                        message: "Address must be letters and numbers only",
                      },
                    })}
                  />
                  {errors?.address && (
                    <span className="error">{errors?.address?.message}</span>
                  )}
                </div>
              </div>
              <div>
                <div className="contact-form-input">
                  <label htmlFor="phone">{t("contact.phoneNumber")}</label>
                  <div className="contact-form-input--phone">
                    <input
                      type="number"
                      id="phone"
                      style={{
                        border: " 1px solid var(--gray-300, #d0d5dd",
                        borderRadius: "8px",
                      }}
                      placeholder={t("contact.phonePlaceholder")}
                      {...register("phone", {
                        required: true,
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Phone number must be numbers only",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="contact-form-input">
                  <label htmlFor="boutique">{t("contact.boutique")}</label>
                  <select
                    name="boutique"
                    id="boutique"
                    {...register("shop_id", {
                      required: true,
                    })}
                  >
                    {shops?.map((shop) => (
                      <option key={shop?.id} value={shop?.id}>
                        {shop?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className="contact-form-input">
                  <label htmlFor="date">{t("contact.date")}</label>
                  <input
                    type="date"
                    id="date"
                    {...register("service_day", {
                      required: true,
                    })}
                  />
                </div>
                <div className="contact-form-input">
                  <label htmlFor="time">{t("contact.time")}</label>
                  <input
                    type="time"
                    id="time"
                    {...register("service_hour", {
                      required: true,
                    })}
                  />
                </div>
              </div>
              {successMessage && <p className="success">{successMessage}</p>}
              {errorMessage && <p className="error">{errorMessage}</p>}
              <div className="contact-form-submit">
                <button type="submit" style={{ cursor: "pointer" }}>
                  {t("contact.reserved")}
                </button>
              </div>
            </form>
          </div>
          <div className="contact-img">
            <div style={{ backgroundImage: `url('${rv}')` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
