import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import LogoImage from '../assets/images/logo.svg';
import { auth } from '../configs/firebase';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';

const AuthLayout = () => {
  const navigate = useNavigate();
  const setLoading = useAuthStore((state) => state.setLoading);
  const login = useAuthStore((state) => state.login);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setLoading(false);
      login(user ?? null);
      if (user && !user?.displayName) navigate('/auth/register');
    });

    return () => {};
  }, [navigate, login, setLoading]);

  return (
    <div className="auth_layout">
      <div className="login_page">
        <div className="login_page__logo">
          <img src={LogoImage} alt="logo" />
        </div>
        <div className="login_page__form">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
