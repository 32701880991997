import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import BestSale from '../../components/best-sale/BestSale';
import Description from './Description';
import ProductDetails from './ProductDetails';
import useFetch from '../../hooks/useFetch';
import { API_URL } from '../../constants/api';
import { useNavigate } from 'react-router-dom';
function DetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: products } = useFetch(`${API_URL}/products?populate=image&pagination[pageSize]=100`);
  const { data: product, error: errorProduct } = useFetch(`${API_URL}/products/${id}?populate=image`);

  if (errorProduct) {
    navigate('/');
  }

  return (
    <Fragment>
      <div className="details_desplay">
        <ProductDetails product={product?.data?.attributes} id={product?.data?.id} />
        <Description product={product?.data?.attributes} id={product?.data?.id} />
      </div>
      <BestSale bestProducts={products?.data} />
    </Fragment>
  );
}

export default DetailsPage;
