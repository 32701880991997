import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import HeartIcon from '../../assets/images/icons/icon coeur aime vert.svg';
import MailIcon from '../../assets/images/icons/icon mail.svg';
import CardIcon from '../../assets/images/icons/icon panier.svg';
import ProductsIcon from '../../assets/images/icons/icon produits white.svg';
import ProfileIcon from '../../assets/images/icons/icon profil.svg';
import SearchIcon from '../../assets/images/icons/icon recherche.svg';
import PhoneIcon from '../../assets/images/icons/icon telephone.svg';
import LogoImage from '../../assets/images/logo.svg';
import { auth } from '../../configs/firebase';
import { API_URL } from '../../constants/api';
import useFetch from '../../hooks/useFetch';
import { countryFlags } from '../../libs/i118n';
import { useAuthStore } from '../../store/auth';
import { useCartStore } from '../../store/useCartStore';
import { useFavoriteStore } from '../../store/useFavoriteStore';
import BottomNavBar from './BottomNavBar';
import CategoryDropdown from './CategoryDropdown';

const SearchResults = ({ products, toggle, searchResRef }) => {
  const navigate = useNavigate();
  const limit = 10;
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!searchResRef.current?.contains(e.target)) {
        toggle();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [searchResRef, toggle]);

  return (
    <ul className="header-bottom-right-search_group-field-results">
      {products?.slice(0, limit).map((product) => (
        <li
          key={product.id}
          className="products_dropdown__item"
          onClick={() => {
            navigate(`/details/${product.id}`);
            toggle();
          }}
        >
          <img
            src={
              product?.attributes?.image?.data
                ? `${API_URL.replace('/api', '')}${product?.attributes?.image?.data[0]?.attributes?.url}`
                : null
            }
            alt={product.name}
          />
          <div>
            <div>{product?.attributes.name}</div>
            <div className="category_name">{product?.attributes.category_name}</div>
          </div>
        </li>
      ))}

      {!products?.length && (
        <div className="center">
          <div>Aucun résultat</div>
        </div>
      )}
    </ul>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const searchResRef = useRef(null);
  const [showCategoriesDropdown, toggleCategoriesDropdown] = useToggle();
  const [showSearchResults, toggleSearchResults] = useToggle();
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { data: products } = useFetch(`${API_URL}/products?populate=image&pagination[pageSize]=100`);
  const { data: countries } = useFetch(`${API_URL}/countries`);
  const { data: customers } = useFetch(`${API_URL}/customers`);
  const [country, setCountry] = useState('fr');
  const { t, i18n } = useTranslation();
  const user = useAuthStore((state) => state.user);
  const setLoading = useAuthStore((state) => state.setLoading);
  const loading = useAuthStore((state) => state.loading);
  const setCostumer = useAuthStore((state) => state.setCostumer);
  const login = useAuthStore((state) => state.login);
  const cart = useCartStore((state) => state.cart);
  const favorite = useFavoriteStore((state) => state.favorite);

  function getCostumer() {
    const customer = customers.find((customer) => customer.auth_id === user?.uid);
    setCostumer(customer ?? null);
  }

  useEffect(() => {
    console.log('country', i18n.language);
    setCountry(i18n.language);

    if (customers?.length > 0) getCostumer();

    auth.onAuthStateChanged((user) => {
      setLoading(false);
      login(user ?? null);
      if (!loading && user && !user?.displayName) navigate('/auth/register');
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, login, navigate, setLoading, customers]);

  const logoutUser = async () => {
    await auth.signOut();
  };

  function to2letter(name) {
    const words = name.split(' ');
    let result = '';
    const firstWord = words[0];
    const lastWord = words[words.length - 1];
    result += firstWord[0];
    if (lastWord) result += lastWord[0];
    return result;
  }

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  const filteredProducts = products?.data?.filter((product) => {
    if (!searchTerm) return false;
    return product?.attributes.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <Fragment>
      <header className="header">
        <div className="header-top">
          <div className="header-top-block">
            <div className="header-top-block-line">
              <img src={PhoneIcon} alt="phone icon" />
              +241 77043131
            </div>
            <div className="header-top-block-line">
              <img src={MailIcon} alt="mail icon" />
              contact@example.com
            </div>
          </div>
          <div className="header-top-block">
            <div className="header-top-block-line">{t('header.timetable')}</div>
            <div className="header-top-block-line">{t('header.weekend')}</div>
          </div>
        </div>
        <div className="header-bottom">
          <Link className="header-bottom-logo" to="/">
            <img src={LogoImage} alt="logo" />
          </Link>
          <div className="header-bottom-right">
            <div className="header-bottom-right-search_group">
              <button onClick={toggleCategoriesDropdown} ref={menuRef}>
                <img src={ProductsIcon} alt="products" />
                {t('header.products')}
                {showCategoriesDropdown && (
                  <CategoryDropdown menuContainerRef={menuRef} toggle={toggleCategoriesDropdown} />
                )}
              </button>
              <div className="header-bottom-right-search_group-field" ref={searchResRef}>
                <input
                  onFocus={toggleSearchResults}
                  onInput={handleSearch}
                  value={searchTerm}
                  placeholder="Rechercher un produits"
                  type="search"
                />
                <img src={SearchIcon} alt="search icon" />
                {showSearchResults && (
                  <SearchResults
                    toggle={toggleSearchResults}
                    products={filteredProducts}
                    searchResRef={searchResRef}
                  />
                )}
              </div>
            </div>
            <div className="header-bottom-right-locale_group">
              <div className="country">
                <img src={countryFlags[country]} alt="icon senegal" />
                <select
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    i18n.changeLanguage(e.target.value);
                  }}
                >
                  {countries?.data?.map((c) => {
                    return (
                      <option key={c.id} value={c?.attributes?.locale.split('-')[0].toLowerCase()}>
                        {c?.attributes?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="currency">
                <select>
                  {countries?.data?.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c?.attributes.currency}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="header-bottom-right-user_info_group relative">
              <div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowUserDropdown(!showUserDropdown);
                    !user && navigate('/auth/login');
                  }}
                >
                  <img src={ProfileIcon} alt="" />
                  {user ? (
                    <div className="header-bottom-right-user_info_group-badge">
                      {to2letter(user.displayName).toLowerCase()}
                    </div>
                  ) : null}
                  {showUserDropdown && (
                    <div>
                      <div className="header-bottom-right-user_info_group-dropdown">
                        <Link to={null} onClick={logoutUser}>
                          se déconnecter
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Link to="/favoris">
                  <img src={HeartIcon} alt="" />

                  <div className="header-bottom-right-user_info_group-badge">{favorite?.length}</div>
                </Link>
              </div>
              <div>
                <Link to="/shopping-cart">
                  <img src={CardIcon} alt="" />

                  <div className="header-bottom-right-user_info_group-badge">{cart?.length}</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="header-bottom-mobile_menu">
            <img src={SearchIcon} alt="search icon" />
          </div>
        </div>
      </header>
      <BottomNavBar />
    </Fragment>
  );
};

export default Header;
