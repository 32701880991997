import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFavoriteStore } from "../../store/useFavoriteStore";
import ListFavorisItems from "./ListFavorisItems";
import MobileItems from "./MobileItems";

function ListFavoris({ products }) {
  const { t } = useTranslation(); // Initialize useTranslation

  const toggleFavorite = useFavoriteStore((state) => state.toggleFavorite);

  return (
    <Fragment>
      <div className="favoris_page__list">
        <table className="favoris_page__list__table">
          <thead>
            <tr>
              <th></th>
              <th>{t("favoris.productName")}</th>
              <th>{t("favoris.unitPrice")}</th>
              <th>{t("favoris.stockStatus")}</th>
              <th>{t("favoris.actions")}</th>
            </tr>
            <tr className="line"></tr>
          </thead>
          <tbody>
            {products?.map((product, index) => (
              <ListFavorisItems
                product={product}
                toggleFavorite={toggleFavorite}
                key={index}
              />
            ))}
          </tbody>
        </table>
      </div>
      {products?.map((product, index) => (
        <MobileItems product={product} key={index} />
      ))}
    </Fragment>
  );
}

export default ListFavoris;
