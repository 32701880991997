import React, { Fragment, useState } from 'react';
import { useAuthStore } from '../../store/auth';
import { useNavigate } from 'react-router-dom';

const LoginVerifyPage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [error, setError] = useState('');
  const login = useAuthStore((state) => state.login);
  const phoneNumber = useAuthStore((state) => state.phoneNumber);

  const onSubmit = () => {
    if (window.confirmationResult === null) return setError('Code de vérification invalide');

    window.confirmationResult
      ?.confirm(code)
      .then((res) => {
        if (!res) return setError('Code de vérification invalide');
        setError('');
        login(res.user);

        if (res.user.displayName) return navigate('/');

        navigate('/auth/register');
      })
      .catch((err) => {
        console.log('Confirm otp error :', err);
        setError("Une erreur s'est produite! Veuillez réessayer plus tard.");
      });
  };

  return (
    <Fragment>
      <div className="login_page__form__title">
        <h2>Bienvenue chez SHS</h2>
        <p>Saisissez le code de vérification envoyé par SMS à {phoneNumber}</p>
      </div>
      <div className="form-group">
        <input
          onInput={(e) => setCode(e.target.value)}
          type="text"
          className="form-control"
          placeholder="000 000"
        />
      </div>
      {error && <div className="form-group">{error}</div>}
      <div className="login_page__form__btn">
        <button onClick={onSubmit}>Continuer</button>
      </div>
      <div id="recaptcha-container"></div>
    </Fragment>
  );
};

export default LoginVerifyPage;
