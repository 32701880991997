import { useMediaQuery } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Add the import for useTranslation
import useFetch from '../../hooks/useFetch';
import QuoteIcon from '../../assets/images/icons/quote.svg';
import { API_URL } from '../../constants/api';

const Testimonial = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const { data: testimonials } = useFetch(`${API_URL}/testimonials`);
  const [page, setPage] = useState(1);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 1024px)');

  const total = testimonials?.length ?? 0;

  const limit = isSmallDevice ? 1 : 2;
  const offset = (page - 1) * limit;
  const paginatedTestimonials = testimonials?.data?.slice(offset, offset + limit);

  return (
    <div className="testimonials">
      <div className="testimonials-top">
        <h4>{t('testimonials.testimonials')}</h4>
        <h3>{t('testimonials.trustedUs')}</h3>
        <p>{t('testimonials.presentation')}</p>
      </div>
      <div className="testimonials-bottom">
        {paginatedTestimonials?.map((testimonial) => (
          <div key={testimonial.id} className="testimonials-bottom-item">
            <div className="testimonials-bottom-item-img"></div>
            <p className="testimonials-bottom-item-text">
              {testimonial.message}
              <img src={QuoteIcon} alt="" />
              <img src={QuoteIcon} alt="" />
            </p>
          </div>
        ))}
      </div>
      <div className="testimonials-paginator">
        {Array.from({ length: Math.ceil(total / limit) }, (_, i) => (
          <div
            key={i}
            className={`${page === i + 1 ? 'active' : ''}`}
            onClick={() => setPage(i + 1)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
