import React, { useEffect, useState } from 'react';
import security from '../../assets/images/icons/securiy.svg';
import car from '../../assets/images/icons/car.svg';
import { FaCheck, FaYoutube } from 'react-icons/fa';
import certified from '../../assets/images/icons/Vector (1).svg';
import wave from '../../assets/images/wave.svg';
import orange from '../../assets/images/orange.svg';
import mastercard from '../../assets/images/master-card.svg';
import stripe from '../../assets/images/stripe.svg';
import paypal from '../../assets/images/paypal.svg';
import visa from '../../assets/images/visa.svg';
import { useCartStore } from '../../store/useCartStore';
import { useFavoriteStore } from '../../store/useFavoriteStore';
import { useCounter } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../constants/api';
function ProductDetails({ product, id }) {
  const { t } = useTranslation();
  const favorite = useFavoriteStore((state) => state.favorite);
  const toggleFavorite = useFavoriteStore((state) => state.toggleFavorite);
  const existFavorite = favorite.find((item) => item.id === id);
  const [image, setImage] = useState(null);
  const [quantity, { increment, decrement }] = useCounter(1, {
    min: 1,
    max: 10,
  });
  const cart = useCartStore((state) => state.cart);
  const existingProduct = cart.find((item) => item.id === id);
  const addToCart = useCartStore((state) => state.addToCart);
  useEffect(() => {
    setImage(
      product?.image?.data
        ? `${API_URL.replace('/api', '')}${product?.image?.data[0]?.attributes?.url}`
        : null,
    );
    window.scrollTo(0, 0);
  }, [product]);

  return (
    <div className="product_details">
      <div className="col1">
        <div className="image_container">
          <img src={image} alt="" />
        </div>
        <div className="thumbnails">
          {product?.image?.data?.map((thumbnail, index) => (
            <img
              key={index}
              src={`${API_URL.replace('/api', '')}${thumbnail?.attributes?.url}`}
              alt=""
              onClick={() => setImage(`${API_URL.replace('/api', '')}${thumbnail?.attributes?.url}`)}
            />
          ))}
        </div>
      </div>
      <div className="col2">
        <div className="heading">
          <div className="stock">
            <span className={product?.availability ? 'in_stock' : 'out_of_stock'}>
              {product?.availability ? t('productDetails.inStock') : t('productDetails.outOfStock')}
            </span>
          </div>
          <div className="video_link">
            <FaYoutube />
            <h2>
              <a href="https://www.youtube.com/">{t('productDetails.watchVideo')}</a>
            </h2>
          </div>
          <div className="name">{product?.name}</div>
        </div>
        <div className="line"></div>
        <div className="description">
          <div className="one_line">
            {product?.properties?.map((property, index) => (
              <h3 key={index}>
                <FaCheck color={'#12a05c'} />
                &nbsp; {property}
              </h3>
            ))}
          </div>
        </div>
        <div className="line"></div>
        <div className="quantities">
          <div className="price">
            <h2>XOF {product?.price}</h2>
            <div onClick={() => toggleFavorite(product)} style={{ cursor: 'pointer' }}>
              {!existFavorite ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 512 512"
                  fill="#232323"
                >
                  <path
                    stroke="#232323"
                    d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 512 512"
                  fill="#232323"
                >
                  <path
                    stroke="#232323"
                    d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                  />
                </svg>
              )}
            </div>
          </div>
          {!existingProduct ? (
            <div className="quantity">
              <h3>{t('productDetails.quantity')}</h3>
              <div className="quantity_input_container">
                <div className="quantity_input">
                  <button className="minus" aria-label="Decrease" onClick={decrement}>
                    &minus;
                  </button>
                  <input
                    type="number"
                    className="input-box"
                    value={quantity}
                    readOnly
                    min="1"
                    max="10"
                  />
                  <button className="plus" aria-label="Increase" onClick={increment}>
                    &#43;
                  </button>
                </div>
                <div className="button">
                  <button onClick={() => addToCart({ ...product, quantity })}>
                    {t('interestedProduct.addToCartButton')}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col3">
        <div className="top">
          <div className="one_line">
            <img src={security} alt="" />
            <p>{t('footing.security')}</p>
          </div>
          <div className="one_line">
            <img src={certified} alt="" />
            <p>{t('footing.certify')}</p>
          </div>
          <div className="one_line">
            <img src={car} alt="" />
            <p>{t('footing.delivery')}</p>
          </div>
        </div>
        <div className="line"></div>
        <div className="bottom">
          <img src={visa} alt="" />
          <img src={paypal} alt="" />
          <img src={mastercard} alt="" />
          <img src={orange} alt="" />
          <img src={stripe} alt="" />
          <img src={wave} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
