import React from 'react';
import { useTranslation } from 'react-i18next';
import FormPayment from './FormPayment';
import MethodPayment from './MethodPayment';
import { useForm } from 'react-hook-form';
import { usePaymentStore } from '../../store/usePaymentStore';
import { useNavigate } from 'react-router-dom';

function PaymentSection() {
  const setPaymentInfos = usePaymentStore((state) => state.setPaymentInfos);
  const paymentInfos = usePaymentStore((state) => state.paymentInfos);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: paymentInfos });
  const { t } = useTranslation(); // Initialize useTranslation

  const onSubmit = (data) => {
    setPaymentInfos(data);
    navigate('/payment/confirmation');
  };

  return (
    <div className="payment_page__section">
      <div className="verification_page__info_customer__verify__header">
        <p>{t('paymentSection.customerInfo')}</p>
        <p>{t('paymentSection.payment')}</p>
        <p>{t('paymentSection.confirmation')}</p>
      </div>
      <div className="verification_page__info_customer__verify__line">
        <div></div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="payment_page__section__content">
        <MethodPayment register={register} errors={errors} />
        <div className="verification_page__info_customer__verify__line">
          <div></div>
        </div>
        {watch('payment') === 'mastercard' || watch('payment') === 'mtn' ? (
          <FormPayment register={register} errors={errors} />
        ) : (
          <div className="payment_page__section__content__form">
            <div style={{ margin: '0', width: '100%' }} className="form-group">
              <input
                type="text"
                style={{ margin: '0', width: '100%' }}
                className="form-control"
                {...register('phoneNumber', { required: true })}
              />
              <label htmlFor="">
                Numero telephone <span className="gl-form-asterisk"></span>
              </label>
            </div>
          </div>
        )}
        <div className="payment_page__section__content__form__btn">
          <button>{t('paymentSection.confirmPayment')}</button>
        </div>
      </form>
    </div>
  );
}

export default PaymentSection;
