import { useTranslation } from 'react-i18next';
import { useFetch } from 'usehooks-ts';

/**
 *
 * @param {string|undefined|null} url
 * @param {RequestInit} options
 */
const useFetchCustom = (url, options = {}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const baseUrl = url ? `${url.split('?')[0]}` : null;
  const params = url ? url.split('?')[1] : null;
  const customUrl = `${baseUrl}?locale=${language}&${params}`;

  return useFetch(customUrl, options);
};

export default useFetchCustom;
