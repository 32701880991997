import React from "react";

function Quantity({ quantity, increment, decrement }) {
  return (
    <div className="quantity_input">
      <button className="minus" onClick={decrement} aria-label="Decrease">
        &minus;
      </button>
      <input
        type="number"
        className="input-box"
        value={quantity}
        readOnly
        min="1"
        max="10"
      />
      <button className="plus" onClick={increment} aria-label="Increase">
        &#43;
      </button>
    </div>
  );
}

export default Quantity;
