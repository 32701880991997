import React from "react";
import { useTranslation } from "react-i18next";
import LogoImage from "../../assets/images/logo.svg";
import InstagramIcon from "../../assets/images/icons/icon instagram.svg";
import YouTubeIcon from "../../assets/images/icons/icon youtube.svg";
import PintrestIcon from "../../assets/images/icons/icon pintrest.svg";
import LinkdinIcon from "../../assets/images/icons/icon linkdin.svg";
import PhoneIcon from "../../assets/images/icons/icon telephone.svg";
import MailIcon from "../../assets/images/icons/icon mail.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-left">
        <div>
          <img src={LogoImage} alt="logo" />
        </div>
        <div className="footer-left-address">
          <p>{t("footer.address")}</p>
          <a href="/">{t("footer.showOnMap")}</a>
        </div>
        <div className="footer-left-social_media">
          <a href="/">
            <img src={InstagramIcon} alt="" />
          </a>
          <a href="/">
            <img src={YouTubeIcon} alt="" />
          </a>
          <a href="/">
            <img src={PintrestIcon} alt="" />
          </a>
          <a href="/">
            <img src={LinkdinIcon} alt="" />
          </a>
        </div>
      </div>
      <div className="footer-right">
        <div className="footer-right-contact">
          <h3>{t("footer.contact")}</h3>
          <div>
            <div className="footer-right-contact-phone_horaire">
              <div>
                <img src={PhoneIcon} alt="" />
              </div>
              <div>
                <h4>{t("footer.phoneNumber")}</h4>
                <p>{t("footer.weekdaysTimings")}</p>
                <p>{t("footer.weekendTimings")}</p>
              </div>
            </div>
            <hr />
            <div className="footer-right-contact-email">
              <img src={MailIcon} alt="" />
              {t("footer.email")}
            </div>
          </div>
        </div>
        <div className="footer-right-util_links">
          <div>
            <h3>{t("footer.information")}</h3>
            <ul>
              <li>
                <a href="http://">{t("footer.aboutUs")}</a>
              </li>
              <li>
                <a href="http://">{t("footer.deliveryInformation")}</a>
              </li>
              {/* ... Autres éléments d'information ... */}
            </ul>
          </div>
          <div>
            <h3>{t("footer.account")}</h3>
            <ul>
              <li>
                <a href="http://">{t("footer.myAccount")}</a>
              </li>
              <li>
                <a href="http://">{t("footer.myOrders")}</a>
              </li>
              <li>
                <a href="http://">{t("footer.returns")}</a>
              </li>
              <li>
                <a href="http://">{t("footer.shipping")}</a>
              </li>
              <li>
                <a href="http://">{t("footer.wishList")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
