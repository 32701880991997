import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarIcon from "../../assets/images/icons/icon calendar.svg";
import LocationIcon from "../../assets/images/icons/icon localisation.svg";
import FormEvent from "./FormEvent";
import useFetch from '../../hooks/useFetch.js';
import { API_URL } from "../../constants/api.js";

const Event = () => {
  const { t } = useTranslation(); // Initialisez useTranslation
  const [isClicked, setIsClicked] = useState(false);
  const { data: events } = useFetch(`${API_URL}/events`);

  return (
    <Fragment>
      {events?.data?.map((event) => (
        <div className="event" key={event?.id}>
          <h3>
            {event?.title} <span>👋</span>
          </h3>
          <div className="event-infos">
            <div>
              <div>
                <img src={LocationIcon} alt="" />
                {event?.address}
              </div>
              <div>
                <img src={CalendarIcon} alt="" />
                {event?.date}
              </div>
            </div>
          </div>
          <div className="event-desc">
            <p>{event?.description}</p>
          </div>
          <div className="event-btn">
            <button onClick={() => setIsClicked(!isClicked)}>
              {t("event.book")}
            </button>
          </div>
          {isClicked && <FormEvent id={event?.id} />}
        </div>
      ))}
    </Fragment>
  );
};

export default Event;
