import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import HeroImage from '../../assets/images/hero image.png';

const Hero = () => {
  const { t } = useTranslation(); // Initialisez useTranslation

  const heroSliders = Array.from({ length: 4 }).map((_, index) => index + 1);

  return (
    <Swiper
      modules={[Pagination]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      className="hero-slider"
    >
      {heroSliders.map((heroSlider) => (
        <SwiperSlide key={heroSlider}>
          <div className="hero">
            <img className="hero-image" src={HeroImage} alt="" />
            <div className="hero-text">
              <h2>
                {t('hero.availableProducts')} <b>{t('hero.available')}</b>
                <br /> {t('hero.contactNow')} <br /> <b>{t('hero.contactUs')}</b>
              </h2>
              <p>{t('hero.carefullyCraftedProducts')}</p>
              <div className="btn">
                <a href="/">{t('hero.buyNow')}</a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Hero;
