import React from 'react';
import Quantity from './Quantity';
import { API_URL } from '../../constants/api';

function CartItem({ item, deleteItemCart, increment, decrement }) {
  return (
    <div className="item-contents" key={item.id}>
      <div className="delete" onClick={() => deleteItemCart(item)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
          <path
            d="M7.64887 6.5L13 1.14887L11.8511 0L6.5 5.35113L1.14887 0L0 1.14887L5.35113 6.5L0 11.8511L1.14887 13L6.5 7.64887L11.8511 13L13 11.8511L7.64887 6.5Z"
            fill="black"
          />
        </svg>
      </div>
      <img
        src={
          item?.image?.data
            ? `${API_URL.replace('/api', '')}${item?.image?.data[0]?.attributes?.url}`
            : null
        }
        alt=""
      />
      <div className="name">{item?.name} </div>
      <div className="prix">{item?.price}</div>
      <Quantity
        quantity={item?.quantity}
        increment={() => increment(item)}
        decrement={() => decrement(item)}
      />
      <div className="total">{item?.price * item?.quantity} </div>
    </div>
  );
}

export default CartItem;
