import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CommandePage from '../../components/your_order/CommandePage';
import { usePaymentStore } from '../../store/usePaymentStore';

function InfoCustomer() {
  const customer = usePaymentStore((state) => state.customer);
  const clientInfos = usePaymentStore((state) => state.clientInfos);
  const setClientInfos = usePaymentStore((state) => state.setClientInfos);
  const defaultValues = clientInfos ?? customer ?? {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setClientInfos(data);
    navigate('/payment');
  };
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div className="verification_page__info_customer" onSubmit={handleSubmit(onSubmit)}>
      <div className="verification_page__info_customer__verify">
        <div className="verification_page__info_customer__verify__header">
          <p>{t('infoCustomer.info')}</p>
          <p>{t('infoCustomer.payment')}</p>
          <p>{t('infoCustomer.confirmation')}</p>
        </div>
        <div className="verification_page__info_customer__verify__line">
          <div></div>
        </div>
        <div className="verification_page__info_customer__verify__body">
          <div className="verification_page__info_customer__verify__body__title">
            <p>{t('infoCustomer.clientInfo')}</p>
          </div>
          <div className="verification_page__info_customer__verify__body__content">
            <form className="verification_page__info_customer__verify__body__content__form">
              <div className="verification_page__info_customer__verify__body__content__form__name_info">
                <div className="verification_page__info_customer__verify__body__content__form__name_info__fisrt_name">
                  <p>{t('infoCustomer.firstName')}</p>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder={t('infoCustomer.firstNamePlaceholder')}
                    {...register('fname', { required: true })}
                    aria-invalid={errors.fname ? 'true' : 'false'}
                  />
                </div>
                <div className="verification_page__info_customer__verify__body__content__form__name_info__last_name">
                  <p>{t('infoCustomer.lastName')}</p>
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    placeholder={t('infoCustomer.lastNamePlaceholder')}
                    {...register('lname', { required: true })}
                    aria-invalid={errors.lname ? 'true' : 'false'}
                  />
                </div>
              </div>
              <div className="verification_page__info_customer__verify__body__content__form__contact_info">
                <div className="verification_page__info_customer__verify__body__content__form__name_info__email">
                  <p>{t('infoCustomer.email')}</p>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t('infoCustomer.emailPlaceholder')}
                    {...register('email', { required: true })}
                    aria-invalid={errors.email ? 'true' : 'false'}
                  />
                </div>
                <div className="verification_page__info_customer__verify__body__content__form__name_info__phone">
                  <p>{t('infoCustomer.mobilePhone')}</p>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder={t('infoCustomer.mobilePhonePlaceholder')}
                    {...register('phone', { required: true })}
                    aria-invalid={errors.phone ? 'true' : 'false'}
                  />
                </div>
              </div>
              <div className="verification_page__info_customer__verify__body__content__form__deliver_title">
                <p>{t('infoCustomer.deliveryInfo')}</p>
              </div>
              <div className="verification_page__info_customer__verify__body__content__form__deliver_info">
                <div className="verification_page__info_customer__verify__body__content__form__deliver_info__deliver_date">
                  <p>{t('infoCustomer.deliveryDate')}</p>
                  <input
                    type="date"
                    name="deliver_date"
                    id="deliver_date"
                    placeholder={t('infoCustomer.deliveryDatePlaceholder')}
                    {...register('deliver_date', { required: true })}
                    aria-invalid={errors.deliver_date ? 'true' : 'false'}
                  />
                </div>
                <div className="verification_page__info_customer__verify__body__content__form__deliver_info__time">
                  <p>{t('infoCustomer.preferredTime')}</p>
                  <input
                    type="time"
                    name="time"
                    id="time"
                    placeholder={t('infoCustomer.preferredTimePlaceholder')}
                    {...register('time', { required: true })}
                    aria-invalid={errors.time ? 'true' : 'false'}
                  />
                </div>
              </div>
              <div className="verification_page__info_customer__verify__body__content__form__address_info">
                <div className="verification_page__info_customer__verify__body__content__form__address_info__town">
                  <p>{t('infoCustomer.city')}</p>
                  <input
                    type="text"
                    style={{ maxWidth: '200px', width: '100%' }}
                    placeholder='Dakar'
                    {...register('city', { required: true })}
                    aria-invalid={errors.city ? 'true' : 'false'}
                  />
                </div>
                <div className="verification_page__info_customer__verify__body__content__form__address_info__address">
                  <p>{t('infoCustomer.address')}</p>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    style={{ maxWidth: '200px', width: '100%' }}
                    placeholder={t('infoCustomer.addressPlaceholder')}
                    {...register('address', { required: true })}
                    aria-invalid={errors.address ? 'true' : 'false'}
                  />
                </div>
                <div className="verification_page__info_customer__verify__body__content__form__address_info__zip">
                  <p>{t('infoCustomer.zipCode')}</p>
                  <input
                    type="text"
                    name="zip"
                    id="zip"
                    style={{ maxWidth: '200px', width: '100%' }}
                    placeholder={t('infoCustomer.zipCodePlaceholder')}
                    {...register('zip', { required: true })}
                    aria-invalid={errors.zip ? 'true' : 'false'}
                  />
                </div>
              </div>
              <div className="verification_page__info_customer__verify__body__content__form__payment">
                <button type="submit">{t('infoCustomer.proceedPayment')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CommandePage />
    </div>
  );
}

export default InfoCustomer;
