import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactImage from "../../assets/images/contact.png";
import BorderTopIcon from "../../assets/images/icons/icon contact border 1.svg";
import BorderBottomIcon from "../../assets/images/icons/icon contact border 2.svg";
import { useForm } from "react-hook-form";
import { API_URL } from "../../constants/api.js";
const Contact = () => {
  const { t } = useTranslation(); // Initialisez useTranslation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [policy, setPolicy] = useState(false);
  const [policyMessage, setPolicyMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const sendData = async (data) => {
    if (!policy) {
      setPolicyMessage(t("contact.policy"));
      setTimeout(() => {
        setPolicyMessage("");
      }, 10000);
      return;
    }
    const response = await fetch(`${API_URL}/messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      reset();
      setSuccessMessage(t("homeFormSuccess.success"));
      setTimeout(() => {
        setSuccessMessage("");
      }, 10000);
    } else {
      setSuccessMessage(t("homeFormSuccess.error"));
      setTimeout(() => {
        setSuccessMessage("");
      }, 10000);
    }
  };
  return (
    <div className="contact">
      <div className="contact-form">
        <h3>{t("contact.contactUs")}</h3>
        <p>{t("contact.ourFriendlyTeam")}</p>
        <form
          onSubmit={handleSubmit((data) => {
            sendData(data);
          })}
        >
          <div>
            <div className="contact-form-input">
              <label htmlFor="firstName">{t("contact.firstName")}</label>
              <input
                type="text"
                id="firstName"
                placeholder={t("contact.firstNamePlaceholder")}
                {...register("fname", {
                  required: true,
                  pattern: {
                    value: /^[A-Za-z ]+$/i,
                    message: "First name must be letters only",
                  },
                })}
              />
              {errors?.fname && (
                <span className="error">{errors?.fname?.message}</span>
              )}
            </div>
            <div className="contact-form-input">
              <label htmlFor="lastName">{t("contact.lastName")}</label>
              <input
                type="text"
                id="lastName"
                placeholder={t("contact.lastNamePlaceholder")}
                {...register("lname", {
                  required: true,
                  pattern: {
                    value: /^[A-Za-z ]+$/i,
                    message: "Last name must be letters only",
                  },
                })}
              />
              {errors?.lname && (
                <span className="error">{errors?.lname?.message}</span>
              )}
            </div>
          </div>
          <div>
            <div className="contact-form-input">
              <label htmlFor="email">{t("contact.email")}</label>
              <input
                type="text"
                id="email"
                placeholder={t("contact.emailPlaceholder")}
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Email must be valid",
                  },
                })}
              />
              {errors?.email && (
                <span className="error">{errors?.email?.message}</span>
              )}
            </div>
          </div>
          <div>
            <div className="contact-form-input">
              <label htmlFor="phone">{t("contact.phoneNumber")}</label>
              <div className="contact-form-input--phone">
                <select {...register("country_id", { required: false })}>
                  <option value="SN">SN</option>
                </select>
                <input
                  type="number"
                  id="phone"
                  placeholder={t("contact.phonePlaceholder")}
                  {...register("phone", {
                    required: true,
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "Phone number must be numbers only",
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="contact-form-input">
              <label htmlFor="message">{t("contact.message")}</label>
              <textarea
                id="message"
                rows={5}
                {...register("message", {
                  required: true,
                })}
              ></textarea>
            </div>
          </div>
          <div className="contact-form-politique">
            <input
              type="checkbox"
              id="policy"
              name="policy"
              checked={policy}
              onChange={() => setPolicy(!policy)}
            />
            <p>{t("contact.privacyPolicy")}</p>
          </div>
          {policyMessage && <p className="error">{policyMessage}</p>}
          {successMessage && <p className="success">{successMessage}</p>}
          <div className="contact-form-submit">
            <button type="submit" style={{ cursor: "pointer" }}>
              {t("contact.sendMessage")}
            </button>
          </div>
        </form>
      </div>
      <div className="contact-img">
        <div style={{ backgroundImage: `url('${ContactImage}')` }}></div>
        <img className="contact-img-borderTop" src={BorderTopIcon} alt="" />
        <img
          className="contact-img-borderBottom"
          src={BorderBottomIcon}
          alt=""
        />
      </div>
    </div>
  );
};

export default Contact;
