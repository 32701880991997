import React from 'react';
import { useTranslation } from 'react-i18next';

function FormPayment({ register, errors }) {
  const { t } = useTranslation(); // Initialize useTranslation

  return (
    <div className="payment_page__section__content__form">
      <div className="column1">
        <div className="card_name">
          <div className="form-group">
            <input
              id="cardNumber"
              className="form-control"
              type="text"
              placeholder=""
              {...register('cardNumber', { required: true })}
              aria-invalid={errors.cardNumber ? 'true' : 'false'}
            />
            <label htmlFor="cardNumber">
              {t('paymentForm.cardNumber')}
              <span className="gl-form-asterisk"></span>
            </label>
          </div>
        </div>
        <div className="owner_name">
          <div className="form-group">
            <input
              id="ownerName"
              className="form-control"
              type="text"
              placeholder=""
              {...register('ownerName', { required: true })}
              aria-invalid={errors.ownerName ? 'true' : 'false'}
            />
            <label htmlFor="ownerName">
              {t('paymentForm.ownerName')}
              <span className="gl-form-asterisk"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="column2">
        <div className="cvv">
          <div className="form-group">
            <input
              id="cvv"
              className="form-control"
              type="text"
              placeholder=""
              {...register('cvv', { required: true })}
              aria-invalid={errors.cvv ? 'true' : 'false'}
            />
            <label htmlFor="cvv">
              {t('paymentForm.cvv')}
              <span className="gl-form-asterisk"></span>
            </label>
          </div>
        </div>
        <div className="validity">
          <div className="form-group">
            <input
              id="validity"
              className="form-control"
              type="text"
              placeholder=""
              {...register('validity', { required: true })}
              aria-invalid={errors.validity ? 'true' : 'false'}
            />
            <label htmlFor="validity">
              {t('paymentForm.validity')}
              <span className="gl-form-asterisk"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormPayment;
