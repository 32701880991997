import { updateProfile } from 'firebase/auth';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import { fetchApi } from '../../apis/fetch';
import { API_URL } from '../../constants/api';

const Register = () => {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const loadingUser = useAuthStore((state) => state.loading);
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState('');

  useEffect(() => {
    //if (!loadingUser && user?.displayName) return navigate('/');
    return () => {};
  }, [user, navigate, loadingUser]);

  const onSubmit = async (data) => {
    try {
      if (!user) return navigate('/');
      setError('');

      await updateProfile(user, {
        displayName: `${data.fname} ${data.lname}`,
      });

      await fetchApi(`${API_URL}/customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data, auth_id: user.uid, phone: user.phoneNumber }),
      });

      window.location.href = '/';
    } catch (error) {
      console.log('Register error :', error);
      setError("Une erreur s'est produite! Veuillez réessayer plus tard.");
    }
  };

  return (
    <Fragment>
      <div className="login_page__form__title">
        <h2>Bienvenue chez SHS</h2>
        <p>Continuer la création de votre compte.</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Prénom"
            {...register('fname', { required: true })}
            aria-invalid={errors?.firstName ? 'true' : 'false'}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Nom"
            {...register('lname', { required: true })}
            aria-invalid={errors?.lastName ? 'true' : 'false'}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            {...register('email', { required: true })}
            aria-invalid={errors?.lastName ? 'true' : 'false'}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Adresse"
            {...register('address', { required: true })}
            aria-invalid={errors?.lastName ? 'true' : 'false'}
          />
        </div>
        {error && <div className="form-group error center">{error}</div>}
        <div className="login_page__form__btn">
          <button type="submit">Continuer</button>
        </div>
      </form>
      <div id="recaptcha-container"></div>
    </Fragment>
  );
};

export default Register;
